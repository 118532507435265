ion-content ion-toolbar {
  --background: translucent;
}

.vendor-banner {
  margin-top: 10pt;
  margin-bottom: 10pt;
  width: max-content;
  margin: auto;
}

.vendor-description {
  margin-top: 10pt;
  margin-bottom: 10pt;
  color: var(--ion-color-primary);
  text-align: center;
}

ion-toolbar {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
}

.main-page-content ion-card {
	--background: var(--ion-color-primary);
	margin: 0;
}

.main-page-content ion-item {
	--background: var(--ion-color-primary);
	--color: #fff;
}

.footer-copy {
	color: var(--ion-color-primary);
}

ion-card ion-icon {
  color: white;
}