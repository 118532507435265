ion-content ion-toolbar {
  --background: translucent;
}

.square {
  width: 50pt !important;
  height: 50pt !important;
  justify-content: center;
}



h3 {
	color: var(--ion-color-primary);
}

.form-description {
  line-height: 1.5;
  color: #444;
}

ion-button {
	--color: #fff;
}